body {
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
  background-color:	#424549;
  color: #fefefe;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.navbar {
  position: fixed;
  width: 96vw;
  display: flex;
  justify-content: center;
  gap: 2vw;
  font-size: 2em;
}